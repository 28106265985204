<template>
  <v-menu v-model="priorityMenu" :disabled="!clickable" offset-y>
    <template v-slot:activator="{ props: menuProps }">
      <v-chip
        v-bind="{ ...$attrs, ...menuProps }"
        :append-icon="clickable? 'mdi-chevron-down' : undefined"
        class="text-uppercase flex-shrink-0 px-2 mr-1"
        :color="color"
        label
        variant="flat"
      >
        {{ priority }}
      </v-chip>
    </template>
    <v-list v-if="clickable" class="qtm-border">
      <v-list-item v-for="(priorityOption, i) in priorities" :key="i" @click="setPriority(priorityOption)">
        <v-list-item-title class="text-capitalize">
          {{ priorityOption }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import type { Order } from '@quotetome/materials-api'

export interface Props {
  order: Order
  clickable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  clickable: false,
})

const priorityMenu = ref(false)

const colorMap = {
  low: 'success',
  medium: 'warning',
  high: 'error',
}
const color = computed(() => colorMap[props.order.priority || 'medium'])

const priorities = [
  'low',
  'medium',
  'high',
]
const priority = computed(() => (props.order.priority === 'medium' ? 'MED' : props.order.priority))

const store = useStore()
const { $api, $error } = useNuxtApp()
const setPriority = async (newPriority: string) => {
  if (newPriority === props.order.priority) {
    return
  }

  const previousPriority = props.order.priority
  const updateData = {
    id: props.order.id,
    priority: newPriority,
  }

  props.order.priority = priority

  try {
    const updatedOrder = await $api.v1.rfqs.update(updateData as any)
    store.commit('admin/updateOrder', updatedOrder)
  }
  catch (error) {
    $error.report(error)
    props.order.priority = previousPriority
  }
}
</script>
