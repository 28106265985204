<template>
  <qtm-select v-bind="$attrs" v-model="reference" clearable hide-details :items="itemNumbers" />
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'match-item-select',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Object,
      required: true
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const itemNumbers = computed(() => (
      props.items.map(item => item.item_number)
    ))
    const reference = computed({
      get() {
        return props.items.find(item => item.internal_identifier === props.modelValue.reference_identifier)?.item_number
      },
      set(itemNumber) {
        const poItem = props.items.find(i => i.item_number === itemNumber)
        const item = { ...props.modelValue }

        item.reference_identifier = poItem?.internal_identifier

        if (!item.cost_code) {
          item.cost_code = poItem?.cost_code
        }

        emit('update:model-value', item)
      }
    })

    return { itemNumbers, reference }
  },
}
</script>
