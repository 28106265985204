<template>
  <qtm-skeleton v-if="loading" />
  <qtm-content-block v-else>
    <v-row>
      <v-col class="py-5" cols="3">
        <qtm-input-label label="User type">
          <qtm-select
            v-model="filter"
            single-line
            :items="['All', 'Staff', 'Customer']"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-timeline density="compact" side="end">
      <order-event-timeline-item v-for="log in filteredLogs" :key="log.id" :event="log" :now="now" />
      <v-timeline-item v-if="!logs.length" fill-dot size="small">
        No events have been recorded for this order
      </v-timeline-item>
    </v-timeline>
  </qtm-content-block>
</template>

<script>
import moment from 'moment'
import OrderEventTimelineItem from '@/components/admin/order-event-timeline-item.vue'

export default {
  name: 'order-event-timeline',
  components: { OrderEventTimelineItem },
  props: {
    order: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      default: true
    },
    now: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      loading: false,
      logs: [],
      nowUpdateInterval: undefined,
      filter: 'All'
    }
  },
  computed: {
    filteredLogs() {
      if (this.filter === 'Staff') {
        return this.logs.filter(l => !l.is_customer_event)
      }
      if (this.filter === 'Customer') {
        return this.logs.filter(l => l.is_customer_event)
      }
      return this.logs
    }
  },
  watch: {
    visible(isVisible) {
      if (isVisible) {
        this.fetchAll()
      }
    }
  },
  mounted() {
    if (this.visible) {
      this.fetchAll()
    }
  },
  methods: {
    async fetchAll() {
      this.loading = true

      const [eventLogs, receiptLogs] = await Promise.all([
        this.fetchLogs(),
        this.fetchReceipts(),
      ])
      const logs = eventLogs.concat(receiptLogs)

      logs.sort((a, b) => b.timestamp - a.timestamp)
      this.logs = logs
      this.loading = false
    },
    async fetchLogs() {
      let logs = []

      try {
        logs = await this.$api.v1.rfqs.eventLog(this.order)
      }
      catch (error) {
        this.$error.report(error)
      }

      return logs
    },
    async fetchReceipts() {
      const po = this.order.pos[0]
      let logs = []

      if (!po) {
        return logs
      }

      try {
        const receiptLogs = await this.$api.v1.purchaseOrders.receipts.list(po.id)

        logs = receiptLogs.map(log => ({
          id: `r-${log.id}`,
          action: 'Order Received',
          context: log,
          timestamp: moment(log.date_created * 1000),
          user: log.user,
          is_customer_event: !log.user.is_staff,
        }))
      }
      catch (error) {
        this.$error.report(error)
      }
      return logs
    },
  }
}
</script>
