<template>
  <v-list class="menu-items" density="compact">
    <v-list-item :to="{name: 'profile'}">
      <template v-slot:prepend>
        <v-icon>mdi-account</v-icon>
      </template>

      <v-list-item-title>
        Profile
      </v-list-item-title>
    </v-list-item>

    <v-divider />

    <v-list-item class="logout-button" @click="logout">
      <template v-slot:prepend>
        <v-icon>mdi-exit-to-app</v-icon>
      </template>

      <v-list-item-title>
        Logout
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'user-menu-items',
  methods: {
    logout() {
      this.$store.commit('auth/setAuthToken', undefined)
      this.$store.commit('auth/setUser', undefined)
      this.$tracker.logOut()
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
