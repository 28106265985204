<template>
  <v-navigation-drawer
    color="white"
    data-test="nav-drawer"
    expand-on-hover
    permanent
    rail
    :width="width"
  >
    <v-list class="pt-0" density="compact" nav>
      <app-nav-item v-for="item in visibleItems" :key="item.label" :item="item" />
    </v-list>

    <template v-slot:append>
      <div class="pb-8 pt-0">
        <user-menu />
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppNavItem from '@/components/app-nav-item.vue'
import UserMenu from '@/components/users/user-menu.vue'

const ACCOUNTING_FAILURES_REFRESH_INTERVAL = 2 * 60 * 1000 // 2 minutes

export default {
  name: 'app-drawer',
  components: { AppNavItem, UserMenu },
  data() {
    return {
      accountingFailuresTimer: 0,
      accountingFailuresCount: 0,
      showInvoicesItem: false,
      width: 224
    }
  },
  computed: {
    ...mapGetters('auth', [
      'canManageCompany',
      'canManageUsers',
      'canUseExpenses',
      'canUseRequisitions',
      'inSetupMode',
      'isStaff',
      'userCompany'
    ]),
    ...mapState('auth', ['user']),
    items() {
      return [
        {
          label: 'Home',
          to: { name: 'dashboard' },
          icon: 'mdi-home-outline',
          show: true,
          dataTest: 'nav-link-home',
        },
        {
          label: 'Orders',
          to: { name: 'orders' },
          icon: 'mdi-clipboard-outline',
          show: !this.inSetupMode,
          dataTest: 'nav-link-orders',
        },
        {
          label: 'Expenses',
          to: { name: 'expenses' },
          icon: 'mdi-currency-usd',
          show: !this.inSetupMode && this.canUseExpenses,
          dataTest: 'nax-link-expenses',
        },
        {
          label: 'Invoices',
          to: { name: 'invoices' },
          icon: 'mdi-file-document-outline',
          show: !this.inSetupMode && this.showInvoicesItem,
          dataTest: 'nav-link-invoices'
        },
        {
          label: 'Requisitions',
          to: { name: 'requisitions' },
          icon: 'mdi-cart-outline',
          show: !this.inSetupMode && (this.isStaff || this.canUseRequisitions),
          dataTest: 'nav-link-reqsuitions'
        },
        {
          label: 'Projects',
          to: { name: 'projects' },
          icon: 'mdi-format-list-checkbox',
          show: true,
          dataTest: 'nav-link-projects',
        },
        {
          label: 'Users',
          to: { name: 'users' },
          icon: 'mdi-account-outline',
          show: this.canManageUsers,
          dataTest: 'nav-link-users',
        },
        {
          label: 'Accounting',
          to: { name: 'accounting-failures' },
          icon: 'mdi-refresh',
          show: this.showAccountingFailureLink,
          dataTest: 'nav-link-accounting-failures',
          countBadge: this.accountingFailuresCount,
        },
        {
          label: 'Accounting Export',
          to: { name: 'accounting-export' },
          icon: 'mdi-file-export-outline',
          dataTest: 'nav-link-accounting-export',
          show: this.isStaff || (this.canManageCompany && !!this.userCompany?.bulk_report_type),
        },
        {
          label: 'Suppliers',
          to: { name: 'suppliers' },
          icon: 'mdi-store-outline',
          show: this.isStaff,
          dataTest: 'nav-link-suppliers',
        },
        {
          label: 'Company Settings',
          to: { name: 'companies-id', params: { id: this.userCompany?.id } },
          icon: 'mdi-cog-outline',
          show: this.canManageCompany,
          dataTest: 'nav-link-company',
        }
      ]
    },
    showAccountingFailureLink() {
      return this.isStaff
    },
    visibleItems() {
      return this.items.filter(item => item.show)
    },
  },
  mounted() {
    if (this.showAccountingFailureLink) {
      this.refreshAccountingFailures()
      this.accountingFailuresTimer = setInterval(this.refreshAccountingFailures, ACCOUNTING_FAILURES_REFRESH_INTERVAL)
    }
    this.setShowInvoices()
  },
  beforeUnmount() {
    if (this.showAccountingFailureLink) {
      clearInterval(this.accountingFailuresTimer)
    }
  },
  methods: {
    async refreshAccountingFailures() {
      const [poFailureCount, expenseFailureCount] = await Promise.all(
        [this.$api.v1.purchaseOrders.getAccountingFailuresCount(), this.$api.v1.expenses.getAccountingFailuresCount()]
      )
      this.accountingFailuresCount = poFailureCount + expenseFailureCount
    },
    setShowInvoices() {
      if (this.isStaff || this.canManageCompany) {
        this.showInvoicesItem = true
      }
      else {
        this.$api.v1.jobsites.list({ authority: this.user.id, invoices: true, limit: 1 })
          .then(response => { this.showInvoicesItem = response.data.length > 0 })
          .catch(this.$error.report)
      }
    }
  }
}
</script>
