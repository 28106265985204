<template>
  <v-list-item v-bind="$attrs" class="py-2 search-item" title="">
    <div class="d-flex justify-space-between">
      <div class="d-flex flex-column justify-space-between">
        <div class="pb-3">
          <div class="qtm-overline text-mid-grey">
            <slot name="reference">
              {{ reference }}
            </slot>
          </div>
          <div class="font-weight-bold text-dark-grey" v-text="title" />
        </div>
        <div>
          <span v-if="projectNumber" class="bg-primary-lighten-1 px-4 py-1 text-mid-dark-grey" v-text="projectNumber" />
        </div>
      </div>
      <div class="d-flex flex-column justify-space-between">
        <div class="d-flex justify-end">
          <img v-if="isStaff" class="company-logo" :src="companyLogo">
        </div>
        <div>
          <vendor-avatar v-if="supplier" size="lg" :vendor="supplier" />
          <user-avatar
            v-if="user"
            class="ml-4"
            color="light-teal"
            size="lg"
            text-color="secondary"
            :user="user"
          />
        </div>
      </div>
    </div>
  </v-list-item>
</template>

<script setup>
import { useStore } from 'vuex'
import VendorAvatar from '@/components/vendors/vendor-avatar.vue'
import UserAvatar from '@/components/users/user-avatar.vue'

defineProps({
  companyLogo: {
    type: String,
    default: '',
  },
  projectNumber: {
    type: String,
    default: '',
  },
  reference: {
    type: String,
    default: '',
  },
  supplier: {
    type: Object,
    default: undefined
  },
  title: {
    type: String,
    default: '',
  },
  user: {
    type: Object,
    default: undefined,
  },
})

const store = useStore()
const isStaff = store.getters['auth/isStaff']
</script>

<style lang="scss" scoped>
$logo-height: 48px;

.company-logo {
  max-height: $logo-height;
  max-width: 2 * $logo-height;
}

.search-item {
  border-bottom: 1px solid rgb(var(--v-theme-light-grey));
}
</style>
