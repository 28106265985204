<template>
  <qtm-dialog-card
    v-bind="$attrs"
    height="90vh"
    title="Match Items"
    width="95vw"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <v-row dense>
      <v-col cols="6">
        <div class="bg-white pa-1 qtm-border">
          <v-row class="qtm-overline text-secondary text-uppercase" dense>
            <v-col cols="5">
              Invoice item
            </v-col>
            <v-col cols="3">
              Price
            </v-col>
            <v-col cols="2">
              Qty.
            </v-col>
            <v-col cols="2" />
          </v-row>
          <v-row
            v-for="item in invoiceItems"
            :key="item.id"
            class="item-row"
            :class="{ 'bg-interactive-lighten-1': hoverItem && hoverItem.id === item.id }"
            dense
            @mouseleave="endHover(item)"
            @mouseover="startHover(item)"
          >
            <v-col cols="5">
              {{ item.description }}
            </v-col>
            <v-col cols="3">
              <div v-if="item.rental_duration_unit">
                <div v-for="key in rentalRateKeys" :key="key">
                  {{ currency(item[`${key}_rate`]) }}/{{ key }}
                </div>
              </div>
              <span v-else>
                {{ currency(item.unit_price) }}/{{ item.unit }}
              </span>
            </v-col>
            <v-col cols="2">
              {{ item.quantity }}
            </v-col>
            <v-col cols="2">
              <match-item-select
                density="compact"
                :items="poItems"
                :model-value="item"
                @update:model-value="$emit('update:item', $event)"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="bg-white pa-1 qtm-border">
          <v-row class="qtm-overline text-secondary text-uppercase" dense>
            <v-col cols="2">
              PO item
            </v-col>
            <v-col cols="5">
              Description
            </v-col>
            <v-col cols="3">
              Price
            </v-col>
            <v-col cols="2">
              Qty.
            </v-col>
          </v-row>
          <v-row
            v-for="item in poItems"
            :key="item.id"
            class="item-row"
            :class="{ 'bg-interactive-lighten-1': matchesRef(item) }"
            dense
          >
            <v-col cols="2">
              {{ item.item_number }}
            </v-col>
            <v-col cols="5">
              {{ item.description }}
            </v-col>
            <v-col cols="3">
              <div v-if="item.rental_duration_unit">
                <div v-for="key in rentalRateKeys" :key="key">
                  {{ currency(item[`${key}_rate`]) }}/{{ key }}
                </div>
              </div>
              <span v-else>
                {{ currency(item.unit_price) }}/{{ item.unit }}
              </span>
            </v-col>
            <v-col cols="2">
              {{ item.quantity }}
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <template v-slot:actions>
      <v-spacer />
      <qtm-btn @click="$emit('update:model-value', false)">
        Done
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script>
import { computed, ref } from 'vue'
import { currency } from '@/models/filters'
import MatchItemSelect from '@/components/invoices/match-item-select.vue'

export default {
  name: 'match-items-dialog',
  components: { MatchItemSelect },
  props: {
    invoiceItems: {
      type: Array,
      default: () => []
    },
    poItems: {
      type: Array,
      default: () => []
    },
  },
  emits: ['update:item', 'update:model-value'],
  setup(props) {
    const rentalRateKeys = [
      'day',
      'week',
      'month',
    ]
    const itemNumbers = computed(() => (
      props.poItems.map(item => item.item_number)
    ))

    const hoverItem = ref(null)
    const startHover = (item) => {
      hoverItem.value = item
    }
    const endHover = (item) => {
      if (hoverItem.value?.id === item.id) {
        hoverItem.value = null
      }
    }
    const matchesRef = (item) => (
      hoverItem.value?.reference_identifier === item.internal_identifier
    )

    return {
      currency,
      endHover,
      hoverItem,
      itemNumbers,
      matchesRef,
      rentalRateKeys,
      startHover,
    }
  },
}
</script>

<style lang="scss" scoped>
.item-row {
  min-height: 48px;
}

.item-row:not(:last-child) {
  border-bottom: 1px solid rgb(var(--v-theme-light-grey));
}
</style>
