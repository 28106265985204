<template>
  <div v-if="!order.lastFullRefresh">
    <qtm-skeleton />
  </div>
  <div v-else-if="primaryAction" class="order-state-actions">
    <qtm-btn
      class="action-button"
      :disabled="isDisabled(primaryAction)"
      :loading="loading"
      :title="actionTip(primaryAction)"
      @click="actionSelected(primaryAction)"
    >
      {{ primaryAction.name }}
    </qtm-btn>

    <v-menu v-if="secondaryActions.length" content-class="qtm-border">
      <template v-slot:activator="{ props }">
        <qtm-icon-btn v-bind="props" icon="mdi-dots-vertical" />
      </template>

      <v-list>
        <v-list-item
          v-for="action in secondaryActions"
          :key="action.name"
          class="action-button"
          :disabled="isDisabled(action)"
          :title="actionTip(action)"
          @click="actionSelected(action)"
        >
          <v-list-item-title>
            {{ action.name }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!order.isInStorePO"
          :loading="loading"
          @click="duplicateOrderDialog = true"
        >
          <v-list-item-title>
            Duplicate Order
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <confirmation-dialog
      v-if="showConfirmationDialog"
      v-model="showConfirmationDialog"
      cancel-button-text="No"
      ok-button-text="Yes"
      :title="confirmOptions.title"
      :width="confirmOptions.width || '25rem'"
      @close="closeConfirmDialog"
      @confirm="takeAction(confirmOptions.action)"
    >
      <component
        :is="confirmOptions.component"
        v-if="confirmOptions.component"
        :order="order"
        :text="confirmOptions.text"
        @update-action="updateAction(confirmOptions.action, $event)"
      />
      <span v-else>{{ confirmOptions.text }}</span>
    </confirmation-dialog>
    <qtm-dialog-card v-model="duplicateOrderDialog" title="Duplicate Order?">
      Duplicated order will include order attachments, order items, quotes, quote items, and quote attachments.
      It will be place into the Order Submitted state.
      <div class="mt-3">
        <qtm-checkbox v-model="moveEmailsToDuplicate" label="Move Emails to Duplicate?" />
      </div>
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn :loading="loading" tertiary xlarge @click="duplicateOrderDialog = false">
          Cancel
        </qtm-btn>
        <qtm-btn :loading="loading" @click="duplicateOrder">
          Duplicate Order
        </qtm-btn>
      </template>
    </qtm-dialog-card>
  </div>
</template>

<script>
import merge from 'lodash.merge'
import ConfirmationDialog from '@/components/confirmation-dialog.vue'

export default {
  name: 'order-state-actions',
  components: { ConfirmationDialog },
  props: {
    order: {
      type: Object,
      required: true,
    },
    executeHook: {
      type: Function,
      required: true
    }
  },
  emits: ['refresh-component'],
  data() {
    return {
      confirmOptions: {},
      duplicateOrderDialog: false,
      loading: false,
      moveEmailsToDuplicate: false,
      showConfirmationDialog: false,
    }
  },
  computed: {
    actions() {
      if (!this.order.state.actions) {
        return []
      }

      return this.order.state.actions
        .filter(action => {
          return action.condition ? action.condition(this.order) : true
        })
    },

    closeAction() {
      const matches = this.actions.filter((action) => action.event === 'close')
      if (matches.length) {
        return matches[0]
      }
      return undefined
    },

    primaryAction() {
      if (this.order.cancelled && this.closeAction) {
        return this.closeAction
      }
      return this.actions[0]
    },

    secondaryActions() {
      if (this.order.cancelled && this.closeAction) {
        return this.actions.filter((action) => action !== this.closeAction)
      }
      return this.actions.slice(1)
    },

    nonTransitionActions() {
      return {
        'cancel': this.takeCancelAction,
        'delete': this.takeDeleteAction,
      }
    }

  },
  methods: {
    async actionSelected(action) {
      let aborted = false
      const abort = () => { aborted = true }

      if (this.isDisabled(action)) {
        return
      }

      if (!action.confirmation) {
        this.takeAction(action)
        return
      }

      await this.executeHook('beforeConfirmation', action, abort)
      if (!aborted) {
        this.confirmOptions = action.confirmation(this.order)
        this.confirmOptions.action = action
        this.showConfirmationDialog = true
      }
    },

    closeConfirmDialog() {
      this.showConfirmationDialog = false
      this.confirmOptions = {}
    },

    async takeAction(action) {
      let aborted = false

      // Abort allows the hook to cancel the transition without requiring it to make a call to the backend
      // that it knows will fail or to raise a superfluous error.
      const abort = () => { aborted = true }

      this.closeConfirmDialog()

      this.loading = true

      if (action.non_transition_state_action) {
        this.handleNonTransitionAction(action)
        this.loading = false
        return
      }

      try {
        await this.executeHook('beforeTransition', action, abort)
        if (!aborted) {
          const newOrder = await this.$api.v1.rfqs.transition(this.order, action.event, { actions: action.actions })

          this.$store.commit('admin/updateOrder', newOrder)
          if (newOrder.isArchived) {
            this.$store.commit('admin/activateOrder', undefined)
          }
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },

    handleNonTransitionAction(action) {
      try {
        this.nonTransitionActions[action.event](action)
      }
      catch (error) {
        this.$error.report(error)
      }
    },

    async takeCancelAction(action) {
      const newOrder = await this.$api.v1.rfqs.cancel(
        this.order.id,
        {
          cancelled_by: action.cancelledBy,
          cancellation_reason: action.cancellationReason,
          cancellation_message: action.cancellationMessage,
          distribute: action.distribute,
        }
      )
      this.$store.commit('admin/updateOrder', newOrder)
      if (newOrder.isArchived) {
        this.$store.commit('admin/activateOrder', undefined)
      }
    },

    async takeDeleteAction() {
      this.loading = true
      try {
        await this.$api.v1.rfqs.delete(this.order)
        this.$store.commit('admin/removeOrder', this.order)
        this.$store.commit('admin/activateOrder', undefined)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },

    isDisabled(action) {
      if (!action.disable) {
        return false
      }
      return !!action.disable(this.order)
    },

    actionTip(action) {
      if (!action.disable) {
        return undefined
      }
      return action.disable(this.order)
    },

    updateAction(action, data) {
      merge(action, data)
    },

    async duplicateOrder() {
      this.loading = true
      try {
        const newOrder = await this.$api.v1.rfqs.duplicate(this.order.id, { move_emails: this.moveEmailsToDuplicate })

        this.$store.commit('admin/addOrder', newOrder)
        this.$store.commit('admin/activateOrder', newOrder)
        this.$toast.success('Order duplicated')
        this.duplicateOrderDialog = false
        this.$emit('refresh-component')
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
  }
}
</script>

<style scoped lang="scss">
.action-button {
  pointer-events: auto;
}
</style>
