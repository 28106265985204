<template>
  <v-menu v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <div v-bind="props" class="wrapper">
        <qtm-text-field
          v-bind="$attrs"
          v-model="query"
          clearable
          hide-details
          :maxlength="SEARCH_MAX_LENGTH"
          placeholder="Search QuoteToMe"
          prepend-inner-icon="mdi-magnify"
          @update:model-value="search"
        />
      </div>
    </template>
    <div v-if="query" class="bg-white qtm-border menu-container">
      <v-tabs v-model="currentTab" class="text-mid-grey" color="interactive" fixed-tabs>
        <v-tab v-for="document in documents" :key="document.value" :value="document.value">
          {{ document.label }}
          <v-chip class="ml-2 text-mid-grey" color="light-grey" density="comfortable" label variant="flat">
            <v-progress-circular v-if="document.loading" indeterminate size="12" width="2" />
            <span v-else v-text="document.results.length" />
          </v-chip>
        </v-tab>
      </v-tabs>
      <v-divider />
      <v-window v-model="currentTab">
        <v-window-item v-for="document in documents" :key="document.value" :value="document.value">
          <v-list v-if="document.results.length" class="results-list">
            <component
              :is="document.component"
              v-for="(result, index) in document.results"
              :key="result.id"
              :document="result"
              @click="trackItemClick(document.value, index)"
            />
            <v-list-item v-if="document.canLoadMore">
              <v-list-item-title>
                <qtm-btn :loading="document.loading" tertiary @click="document.loadMore(query)">
                  Load More
                </qtm-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <div v-else class="font-weight-bold qtm-body text-mid-light-grey text-center pa-6">
            No matching {{ document.label }} found
          </div>
        </v-window-item>
      </v-window>
    </div>
    <div v-else class="bg-white qtm-border menu-container">
      <v-list>
        <v-list-subheader class="text-mid-light-grey">
          Recent Searches:
        </v-list-subheader>
        <v-list-item
          v-for="value in recentSearches"
          :key="value"
          density="compact"
          @click="manualSearch(value)"
        >
          <v-list-item-title class="pl-8">
            {{ value }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </v-menu>
</template>

<script setup>
import debounce from 'lodash.debounce'
import { onMounted, reactive, ref, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import useDocumentSearch from '@/composables/document-search'
import ExpenseDocumentItem from '@/components/search/expense-document-item.vue'
import InvoiceDocumentItem from '@/components/search/invoice-document-item.vue'
import OrderDocumentItem from '@/components/search/order-document-item.vue'
import QuoteDocumentItem from '@/components/search/quote-document-item.vue'
import RequisitionDocumentItem from '@/components/search/requisition-document-item.vue'
import { SEARCH_MAX_LENGTH } from '@/constants'

const menu = ref(false)
const route = useRoute()

watch(route, () => {
  menu.value = false
})

const recentSearches = ref([])

onMounted(() => {
  const storedSearches = JSON.parse(localStorage.getItem('global-search-recentSearches'))

  if (Array.isArray(storedSearches)) {
    recentSearches.value = storedSearches
  }
})

const saveSearch = (value) => {
  if (value?.length >= 3) {
    recentSearches.value = recentSearches.value.filter(s => s !== value)
    recentSearches.value.unshift(value)
    recentSearches.value = recentSearches.value.slice(0, 5)

    localStorage.setItem('global-search-recentSearches', JSON.stringify(recentSearches.value))
  }
}

const nuxtApp = useNuxtApp()
const store = useStore()
const documents = [
  {
    label: 'Orders',
    value: 'order',
    component: shallowRef(OrderDocumentItem),
    include: true,
  },
  {
    label: 'Quotes',
    value: 'quote',
    component: shallowRef(QuoteDocumentItem),
    include: true,
  },
  {
    label: 'Expenses',
    value: 'expense',
    component: shallowRef(ExpenseDocumentItem),
    include: store.getters['auth/canUseExpenses'],
  },
  {
    label: 'Invoices',
    value: 'invoice',
    component: shallowRef(InvoiceDocumentItem),
    include: true,
  },
  {
    label: 'Requisitions',
    value: 'requisition',
    component: shallowRef(RequisitionDocumentItem),
    include: store.getters['auth/canUseRequisitions'],
  },
].filter(document => document.include)
  .map(document => reactive({
    ...document,
    ...useDocumentSearch(document.value),
  }))

const search = debounce((query) => {
  const trimQuery = query?.trim()

  menu.value = true
  const searchPromises = documents.map(document => document.search(trimQuery))
  saveSearch(trimQuery)

  Promise.all(searchPromises).finally(() => {
    const counts = {}
    documents.forEach(document => { counts[`${document.value}_count`] = document.results?.length })
    nuxtApp.$tracker.track('Global Search - Search', { search: trimQuery, ...counts })
  })
}, 350)
const trackItemClick = (document, position) => {
  nuxtApp.$tracker.track('Global Search - Click', { document, position })
}
const query = ref('')
const manualSearch = (value) => {
  query.value = value
  search(value)
}
const currentTab = ref(null)
</script>

<style lang="scss" scoped>
.menu-container,
.wrapper {
  width: 600px;
}

.results-list {
  max-height: 400px;
  overflow-y: auto;
}
</style>
