<template>
  <search-item
    :company-logo="company.logo"
    :project-number="project.project_number"
    :reference="reference"
    :supplier="document.supplier"
    :title="document.order.reference_name"
    :to="{ name: 'invoices-order', params: { order: document.order.id }, query: { invoice: document.id } }"
    :user="document.order.owner"
    @click="$emit('click')"
  />
</template>

<script setup>
import { computed } from 'vue'
import SearchItem from '@/components/search/search-item.vue'

defineEmits(['click'])

const props = defineProps({
  document: {
    type: Object,
    required: true
  },
})

const project = computed(() => props.document.project || {})
const company = computed(() => project.value.company || {})
const reference = computed(() => {
  let refNumber = `#${props.document.invoice_number}`

  if (props.document.purchase_order?.po_number) {
    refNumber = `${refNumber} | ${props.document.purchase_order.po_number}`
  }

  return refNumber
})
</script>
