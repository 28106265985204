<template>
  <qtm-btn
    v-if="validActions.length"
    :append-icon="expand ? 'mdi-chevron-up' : 'mdi-chevron-down'"
  >
    CREATE
    <v-menu v-model="expand" activator="parent" content-class="qtm-border">
      <v-list v-for="action in validActions" :key="action.link">
        <v-list-item
          :data-test="action.dataTest"
          :to="{ name: action.link }"
        >
          <div class="d-flex align-center">
            <v-icon v-if="action.icon" class="mr-2">
              {{ action.icon }}
            </v-icon>
            <q-icon v-else class="mr-2" />
            {{ action.label }}
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </qtm-btn>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import QIcon from '@/components/q-icon.vue'

const store = useStore()

const expand = ref(false)

const auth = computed(() => ({
  canGetQuotes: store.getters['auth/canGetQuotes'],
  canCreatePO: store.getters['auth/canCreatePO'],
  canUseExpenses: store.getters['auth/canUseExpenses'],
  canUseRequisitions: store.getters['auth/canUseRequisitions'],
}))

const actions = computed(() => {
  return [
    {
      label: 'Get Quotes',
      link: 'orders-new',
      dataTest: 'get-quotes-link',
      auth: auth.value.canGetQuotes
    },
    {
      label: 'Create PO',
      link: 'purchase-orders-new',
      icon: 'mdi-shopping-outline',
      dataTest: 'create-po-link',
      auth: auth.value.canCreatePO
    },
    {
      label: 'Create Expense',
      link: 'expenses-new',
      icon: 'mdi-currency-usd',
      dataTest: 'create-expense',
      auth: auth.value.canUseExpenses
    },
    {
      label: 'Create Requisition',
      link: 'requisitions-new',
      icon: 'mdi-format-list-bulleted',
      dataTest: 'create-requisition',
      auth: auth.value.canUseRequisitions
    },
  ]
})

const validActions = computed(() => actions.value.filter((action) => action.auth))

</script>
