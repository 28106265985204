<template>
  <search-item
    v-bind="action"
    :company-logo="companyLogo"
    :project-number="projectNumber"
    :reference="reference"
    :supplier="document.vendor"
    :title="document.order.reference_name"
    :user="document.order.owner"
    @click="$emit('click')"
  >
    <template v-slot:reference>
      <v-icon :color="icon.color">
        {{ icon.icon }}
      </v-icon>
      {{ reference }}
    </template>
  </search-item>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import SearchItem from '@/components/search/search-item.vue'

const props = defineProps<{
  document: any
}>()
defineEmits(['click'])

const companyLogo = computed(() => props.document.order.jobsite?.company.logo)
const projectNumber = computed(() => props.document.order.jobsite?.project_number)
const reference = computed(() => [
  props.document.supplier_reference,
  props.document.order.qtm_reference_number,
  props.document.purchase_order?.po_number
].filter((e?: string) => !!e).join(' | '))
const icon = computed(() => {
  if (!props.document.winning_purchase_order) {
    return { icon: 'mdi-clock' }
  }

  if (props.document.winning_purchase_order === props.document.purchase_order?.id) {
    return { icon: 'mdi-check-circle', color: 'success' }
  }

  return { icon: 'mdi-close-circle', color: 'error' }
})

const store = useStore()
const action = store.getters['auth/isStaff']
  ? { onClick: () => store.commit('admin/activateOrder', { id: props.document.order.id }) }
  : { to: { name: 'orders-id', params: { id: props.document.order.id } } }
</script>
