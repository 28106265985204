<template>
  <div class="order-action-sidebar">
    <order-buyer-notes :order="order" />
    <project-information :order="order">
      <v-card-text>
        <qtm-input-label density="compact" label="CS Agent">
          <order-agent-select :order="order" />
        </qtm-input-label>
      </v-card-text>
    </project-information>
  </div>
</template>

<script>
import ProjectInformation from '@/components/admin/project-information.vue'
import OrderAgentSelect from '@/components/admin/order-agent-select.vue'
import OrderBuyerNotes from '@/components/admin/order-buyer-notes.vue'

export default {
  name: 'order-action-sidebar',
  components: { ProjectInformation, OrderAgentSelect, OrderBuyerNotes },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
.order-action-sidebar * {
  margin-bottom: 1rem;
}
</style>
