<template>
  <v-card class="qtm-border" variant="flat">
    <v-card-title>
      QTM Buyer Notes
    </v-card-title>
    <v-card-text>
      <qtm-textarea v-model="order.qtm_buyer_notes" />
      <qtm-btn secondary @click="saveNote">
        Save
      </qtm-btn>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import type { Order } from '@quotetome/materials-api'

const props = defineProps<{
  order: Order
}>()

const store = useStore()
const { $api, $error, $toast } = useNuxtApp()
const saveNote = async () => {
  const orderData = {
    id: props.order.id,
    qtm_buyer_notes: props.order.qtm_buyer_notes
  }

  try {
    const updatedOrder = await $api.v1.rfqs.update(orderData as any)
    store.commit('admin/updateOrder', updatedOrder)
    $toast.success('Note updated')
  }
  catch (error) {
    $error.report(error)
  }
}
</script>
