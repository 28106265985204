<template>
  <v-menu v-if="order.orderType" v-model="orderTypeMenu" :disabled="!clickable" offset-y>
    <template v-slot:activator="{ props: menuProps }">
      <order-type-chip
        v-bind="{ ...$attrs, ...menuProps }"
        :append-icon="clickableAndChangeable ? 'mdi-chevron-down' : undefined"
        :order-type="order.orderType"
      />
    </template>
    <v-list v-if="clickableAndChangeable" class="qtm-border">
      <v-list-item v-for="(orderType, i) in orderTypes" :key="i" @click="setOrderType(orderType)">
        <v-list-item-title class="text-capitalize pull-right">
          <template v-slot:prepend>
            <v-icon class="mr-1">
              mdi-checkbox-{{ (order.orderType.value === orderType.value) ? 'marked' : 'blank' }}-outline
            </v-icon>
          </template>
          {{ orderType.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import type { Order } from '@quotetome/materials-api'
import OrderTypeChip from '@/components/orders/order-type-chip.vue'

export interface Props {
  order: Order
  clickable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  clickable: false,
})

const orderTypeMenu = ref(false)

const clickableAndChangeable = computed(() => {
  return props.clickable && props.order.allowedOrderTypeChanges.length !== 0 && props.order.canChangeOrderType
})
const orderTypes = computed(() => {
  return props.order.allowedOrderTypeChanges.map((orderType) => ({
    name: orderType.name, value: orderType.value
  }))
})

const store = useStore()
const { $api, $error } = useNuxtApp()
const setOrderType = async (orderType: { name: string, value: number }) => {
  if (orderType.value === props.order.orderType.value) {
    return
  }
  const updateData = {
    id: props.order.id,
    order_type: orderType.value,
  }
  try {
    const updatedOrder = await $api.v1.rfqs.changeOrderType(updateData as any)
    store.commit('admin/updateOrder', updatedOrder)
  }
  catch (error) {
    $error.report(error)
  }
}
</script>
